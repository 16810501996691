
      let head;
      let audioContext = null;
      let isPlaying = false;
      
      import { TalkingHead } from "/src/avatar/talkinghead.mjs";
      
      let microsoftSynthesizer = null;
      const microsoftQueue = [];

      async function initAudioContext() {
        if (!audioContext) {
          audioContext = new (window.AudioContext || window.webkitAudioContext)({
            sampleRate: 22050
          });
        }
        if (audioContext.state === 'suspended') {
          await audioContext.resume();
        }
        return audioContext;
      }

      async function microsoftSpeak(s, language = 'es-ES', node = null) {
        try {
          await initAudioContext();
          
          while (microsoftQueue.length > 0) {
            microsoftQueue.pop();
          }
          
          if (s === null) {
            if (microsoftSynthesizer) {
              microsoftSynthesizer.close();
              microsoftSynthesizer = null;
            }
            return;
          }

          var ssml;
          if(language.includes("es")){
            ssml = "<speak version='1.0' " +
            "xmlns:mstts='http://www.w3.org/2001/mstts' " +
            "xml:lang='es'>" +
            "<voice name='en-US-AvaMultilingualNeural'>" +
            "<prosody rate='1' pitch='0Hz'>" +
            s.replaceAll('&','&amp;').replaceAll('<','&lt;').replaceAll('>','&gt;') +
            "</prosody>" +
            "</voice>" +
            "</speak>";
          }
          else{
            ssml = "<speak version='1.0' " +
            "xmlns:mstts='http://www.w3.org/2001/mstts' " +
            "xml:lang='en'>" +
            "<voice name='en-US-AvaMultilingualNeural'>" +
            "<prosody rate='1' pitch='0Hz'>" +
            s.replaceAll('&','&amp;').replaceAll('<','&lt;').replaceAll('>','&gt;') +
            "</prosody>" +
            "</voice>" +
            "</speak>";
          }

          microsoftQueue.push({
            ssml: ssml,
            node: node,
            speak: { audio: [], words: [], wtimes: [], wdurations: [], visemes: [], vtimes: [], vdurations: [] }
          });

          if (microsoftQueue.length === 1) {
            microsoftProcessQueue();
          }
        } catch (error) {
          console.error('Error in microsoftSpeak:', error);
        }
      }

      async function microsoftProcessQueue() {
        try {
          await initAudioContext();

          if (microsoftQueue.length) {
            const job = microsoftQueue[0];

            if (job === null) {
              microsoftQueue.shift();
              if (microsoftQueue.length === 0 && microsoftSynthesizer) {
                microsoftSynthesizer.close();
                microsoftSynthesizer = null;
              }
            } else {
              if (!microsoftSynthesizer) {
                const config = window.SpeechSDK.SpeechConfig.fromSubscription(
                  "0bb1df78809d487cadaa83c93e93aaf7", 
                  "eastus2"
                );
                
                config.speechSynthesisOutputFormat = 
                  window.SpeechSDK.SpeechSynthesisOutputFormat.Raw22050Hz16BitMonoPcm;

                microsoftSynthesizer = new window.SpeechSDK.SpeechSynthesizer(config, null);

                const visemeMap = [
                  "sil", 'aa', 'aa', 'O', 'E',
                  'E', 'I', 'U', 'O', 'aa',
                  'O', 'I', 'kk', 'RR', 'nn',
                  'SS', 'SS', 'TH', 'FF', 'DD',
                  'kk', 'PP'
                ];

                microsoftSynthesizer.visemeReceived = function(s, e) {
                  if (microsoftQueue[0] && microsoftQueue[0].speak) {
                    const o = microsoftQueue[0].speak;
                    const viseme = visemeMap[e.visemeId];
                    const time = e.audioOffset / 10000;

                    if (o.vdurations.length) {
                      if (o.visemes[o.visemes.length - 1] === 0) {
                        o.visemes.pop();
                        o.vtimes.pop();
                        o.vdurations.pop();
                      } else {
                        o.vdurations[o.vdurations.length - 1] = time - o.vtimes[o.vdurations.length - 1];
                      }
                    }
                    o.visemes.push(viseme);
                    o.vtimes.push(time);
                    o.vdurations.push(75);
                  }
                };

                microsoftSynthesizer.wordBoundary = function(s, e) {
                  if (microsoftQueue[0] && microsoftQueue[0].speak) {
                    const o = microsoftQueue[0].speak;
                    o.words.push(e.text);
                    o.wtimes.push(e.audioOffset / 10000);
                    o.wdurations.push(e.duration / 10000);
                  }
                };
              }

              try {
                await microsoftSynthesizer.speakSsmlAsync(
                  job.ssml,
                  result => {
                    if (microsoftQueue[0] && microsoftQueue[0].speak) {
                      if (result.reason === window.SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                        const job = microsoftQueue[0];
                        if (result.audioData && result.audioData.byteLength > 0) {
                          job.speak.audio = [result.audioData];
                          
                          if (head && head.speakAudio) {
                            isPlaying = true;
                            Promise.resolve(head.speakAudio(job.speak, {
                              sampleRate: 22050,
                              channels: 1
                            }, job.node ? null : null))
                              .then(() => {
                                isPlaying = false;
                              })
                              .catch(error => {
                                console.error('Error en speakAudio:', error);
                                isPlaying = false;
                              });
                          }
                        }
                      }
                      microsoftQueue.shift();
                      microsoftProcessQueue();
                    }
                  },
                  error => {
                    console.error('Error en la síntesis:', error);
                    microsoftQueue.shift();
                    microsoftProcessQueue();
                  }
                );
              } catch (error) {
                console.error('Error al sintetizar voz:', error);
                microsoftQueue.shift();
                microsoftProcessQueue();
              }
            }
          }
        } catch (error) {
          console.error('Error in microsoftProcessQueue:', error);
        }
      }

      const nodeAvatar = document.getElementById('avatar-container');
      head = new TalkingHead(nodeAvatar, {
        jwtGet: null,
        ttsEndpoint: "/api/speech",
        cameraZoomEnable: true,
        cameraPanEnable: true,
        cameraView: 'full',
        avatarMood: 'neutral'
      });
      
      head.showAvatar({
        url: '/assets/avatar/brunette.glb',
        body: 'F',
        avatarMood: 'neutral',
        fi: 'Brunetti'
      }, () => {
        console.log('Avatar loaded and ready');
      });

      window.globalSpeak = microsoftSpeak;      
      window.globalStopSpeak = () => head.stopSpeaking(); 

     
    